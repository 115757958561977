import React from 'react';

const Title = (props) => {
  const { data } = props;

  const dateDifference = () => {
    const dt1 = new Date(data.updated);

    const dt2 = new Date();

    const days = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                 Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
                 (1000 * 60 * 60 * 24));

    const months = dt2.getMonth() - dt1.getMonth() +
                   (12 * (dt2.getFullYear() - dt1.getFullYear()));

    return (days > 30)
           ? (months + ' Month' + ((months > 1) ? 's' : '') + ' Ago')
           : days + ' Days Ago';
  };

  return (
    <div className='title-container'>
      <div className='title-main'>
        <h1 className='h1-small'>Portfolio</h1>
      </div>
      <h4>{data.projects.length} Projects • Last Updated {dateDifference()}</h4>
    </div>
  );
};

export default Title;
