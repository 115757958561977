import React, { useEffect } from 'react';
import Header from '../components/Header';
import Card from '../components/Card';
import Title from '../components/Title'
import projects from '../data/projects.json';
import '../styles/portfolio.css';

const Portfolio = (props) => {

  useEffect(() => {
    document.title = 'Matt Grove • Portfolio';
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header/>

      <div className='main-body'>
        <div className='main'>
          <Title data={ projects }/>
          <div className='card-wrapper'>
            <div className='title-wrapper'>
              <h5 className='accent-1 portfolio-title'>Featured</h5>
            </div>
            <Card project={ projects.projects[projects.featured] }/>
          </div>

          <div className='card-wrapper'>
            <div className='title-wrapper'>
              <h5 className='accent-1 portfolio-title'>All</h5>
            </div>

            {projects.projects.map(project => (
              <Card
                key={ project.page }
                project={ project }/>
            ))}

          </div>

        </div>
      </div>
    </>
  )
}

export default Portfolio;
