import React, { useEffect } from 'react';
import ReactMapboxGl from 'react-mapbox-gl';

const Mapbox = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoibWFmZmV5ZXciLCJhIjoiY2l4cW9tM3NvMDAyaTMzbzhpY2JwbXExciJ9.8CAuFj95F8LWVrLLpx6KNw',
  doubleClickZoom: false,
  dragPan: false,
  dragRotate: false,
  scrollZoom: false,
  touchZoomRotate: false,
});

const Map = (props) => {
  const { map, handleMap } = props;

  useEffect(() => {
    if (map !== undefined) {
      const rotateCamera = (timestamp=0) => {
        map.rotateTo((timestamp / 60) % 360, { duration: 0 });
        requestAnimationFrame(rotateCamera);
      };

      rotateCamera();

      if (!map.getLayer('3d-buildings')) {
        map.addLayer({
            id: '3d-buildings',

            filter: ['==', 'extrude', 'true'],
            type: 'fill-extrusion',
            minzoon: 15,
            source: 'composite',
            'source-layer': 'building',
            paint: {
                'fill-extrusion-color': 'hsl(195, 0%, 30%)',
                'fill-extrusion-height': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    15,
                    0,
                    15.05,
                    ['get', 'height'],
                ],
                'fill-extrusion-base': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    15,
                    0,
                    15.05,
                    ['get', 'min_height'],
                ],
                'fill-extrusion-opacity': 0.6,
              },
          });
      }

    }

  }, [map]);

  return (
    <Mapbox
      id='map'

      // eslint-disable-next-line
      style='mapbox://styles/maffeyew/ck5cirj3v1qdf1cqpndtxigag'
      center={[-2.995859, 53.4058076]}
      zoom={[16.5]}
      onStyleLoad={ handleMap }
      pitch={[45]}
      containerStyle={{
        height: '100%',
        width: '100%',
      }}/>
  );
};

export default Map;
