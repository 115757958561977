import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { rainbow } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Block3 = () => {

  const codeString = `\


  const interests = [
    { name: 'Matt', interest: 'Guitar' },
    { name: 'Matt', interest: 'Cycling' },
    { name: 'Lydia', interest: 'Knitting' },
    { name: 'Matt', interest: 'Photography' },
  ];

  const myInterests = interests.reduce((res, i) => {
    if (i.name === 'Matt') res.push(i.interest);
    return res;
  }, []);

  console.log(myInterests);
  // "Guitar", "Cycling", "Photography"

`;

  return (
    <div className='bg'>
      <div className='flex-container'>
        <div data-aos="fade-in" data-aos-offset="260" className='text-container box' id='bio-3'>
          <div className='text'>
            <h2>
              Working with a modern tech stack
            </h2>
            <div className='bio'>React, JavaScript, HTML/CSS are the main tools I use. </div>
          </div>
        </div>
        <div data-aos="fade-in" data-aos-offset="260" className='text-container box' id='bio-3 order-1'>
            <SyntaxHighlighter
              className='syntax'
              language="javascript"
              style={rainbow}
              showLineNumbers={false}
              wrapLines={true}>
              {codeString}
            </SyntaxHighlighter>

        </div>

      </div>
    </div>
  )
}

export default Block3;
