import React from 'react';
import { Clock, Heart, Prospects } from './Icons';



const Block4 = () => {
  return (
    <div className='bg band-4'>

      <div className='flex-container'>
        <div data-aos="fade-in" data-aos-offset="260" className='text-container box' id='bio-4'>
          <div className='text'>
            <h2>
              Want to have a chat?
            </h2>
            <div className='bio'>Drop me an email and I'll be in touch!</div>
            <a href="mailto:matthewgrove95@gmail.com" className='button'>
              email
            </a>
          </div>
        </div>

        <div data-aos="fade-in" data-aos-offset="260" className='box'>

          <div className='overview-container'>
            <div className='icon-container'>
              <Clock/>
            </div>
            <div className='overview-bio'>Effective time management</div>
          </div>

          <div className='overview-container'>
            <div className='icon-container'>
              <Heart/>
            </div>
            <div className='overview-bio'>Passion for data visualisation</div>
          </div>

          <div className='overview-container'>
            <div className='icon-container'>
              <Prospects/>
            </div>
            <div className='overview-bio'>Open to new prospects</div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Block4;
