import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Project from './pages/Project';
import ErrorPage from './pages/ErrorPage';

const App = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={ Home }/>
        <Route exact path='/portfolio' component={ Portfolio }/>
        <Route path='/portfolio/:id' component={ Project }/>

        <Route component={ ErrorPage }/>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
