import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import Header from '../components/Header';
import '../styles/portfolio.css';
import '../styles/project.css';
import projects from '../data/projects.json';

const Project = (props) => {

  const { match } = props;
  const [ content, setContent ] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    const project = projects.projects.filter(p => p.page === match.params.id)[0];
    document.title = `Matt Grove • ${project.title}`;
    fetch(window.location.pathname + '.md')
    .then(res => res.text())
    .then(post => setContent(post))
  }, [match]);

  const LinkRenderer = (props) => {
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>
  }


  const renderers = {
          image: ({
              alt,
              src,
              title,
          }) => (
              <img
                  alt={alt}
                  src={src}
                  title={title}
                  style={{ maxWidth: '100%' }}  />
          ),
          link: LinkRenderer
      };

  return (
    <>
      <Header/>
      <div className='main-body'>
        <div className='main-narrow'>
          <ReactMarkdown
            allowDangerousHtml
            renderers={ renderers }
            transformImageUri={ uri =>
              uri.startsWith("http://localhost")
              ? `${process.env.REACT_APP_IMAGE_BASE_URL}${uri}`
              : uri
            }>{ content }
          </ReactMarkdown>
        </div>
      </div>
    </>
  )
}

export default Project;
