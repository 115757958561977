import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import Header from '../components/Header';
import Block1 from '../components/Block1';
import Block2 from '../components/Block2';
import Block3 from '../components/Block3';
import Block4 from '../components/Block4';
import '../styles/core.css';
import 'aos/dist/aos.css';

const Home = (props) => {

  const [map, setMap] = useState(undefined);

  useEffect(() => {
    document.title = 'Matt Grove • Home';
    window.scrollTo(0, 0);
    AOS.init();
  }, []);

  const handleMap = (map) => {
    setMap(map);
  };

  return (
    <div>
      <Header/>
      <Block1/>
      <Block2
        map={ map }
        handleMap={ handleMap }
        />
      <Block3/>
      <Block4/>
    </div>
  );
};

export default Home;





// <!-- Take a look at my portfolio for examples of my work. -->
// <!-- <a href="portfolio.html" className='button'>
//   portfolio
// </a> -->




//     <pre><code className="language-javascript">
// // let number = 2;
//
// // square = (number) => {
// //   return Math.pow(number, 2);
// // };
//
// // square(number); // output: 4
//     </code></pre>



// onClick={legendMinimizer()}
