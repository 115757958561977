import React from 'react';

const Block1 = (props) => {
  return (
    <div className='bg band-1'>
      <div className='flex-container' id='container-1'>
        <div data-aos="fade-in" className='text-container box' id='bio-1'>
          <div className='text'>
            <h1 className='h1-large'>
              Matt<br/>Grove
            </h1>
            <div className='bio'>
              I'm a Front End Developer based in Manchester, United Kingdom.
            </div>
          </div>
        </div>
        <div data-aos="fade-in" className='box outer-image-container'>
          <div className='inner-image-container'>
              <img src='images/me.JPG' id='image-1' alt="Matt Grove"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block1;
