import React from 'react';
import Map from './Map';

const Block2 = (props) => {

  const { map, handleMap } = props

  return (
    <div className='bg band-2'>
      <div className='flex-container'>
        <div data-aos="fade-in" data-aos-offset="260" className='text-container box' id='bio-2'>
          <div className='text'>
            <h2>
              I specialise in data visualisation
            </h2>
            <div className='bio'>Especially maps. I have designed maps for the UK Government & construction contractors leading in their game.</div>
          </div>
        </div>
        <div data-aos="fade-in" data-aos-offset="260" className='box map-container'>
          <Map
            map={ map }
            handleMap={ handleMap }/>
        </div>
      </div>
    </div>
  )
}

export default Block2
