const dateFormatter = (shortDate) => {
  if (shortDate === '') {
    return null;
  } else {
    let mydate = new Date(shortDate);
    var month = ['January',
                 'February',
                 'March',
                 'April',
                 'May',
                 'June',
                 'July',
                 'August',
                 'September',
                 'October',
                 'November',
                 'December',
               ][mydate.getMonth()];
    let str = month + ' ' + mydate.getFullYear();
    return (str);
  }
};

export default dateFormatter;
