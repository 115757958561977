import React from 'react';
import { Link } from 'react-router-dom';
import dateFormatter from '../utils/dateFormatter';

const Card = (props) => {

  const { project } = props;


  return (
    <div className='card'>

      <Link to={`/portfolio/${ project.page }`}>
        {project.image && <div className='image-container'>
          <img
            className='image'
            alt={ project.title }
            src={ '/images' + project.image} />
        </div>
        }
      </Link>

      <div className='card-info'>
        <h4 className='portfolio-title'>{ dateFormatter(project.date) }</h4>
        <Link to={`/portfolio/${ project.page }`}>
          <h3 className='spacer'>{ project.title }</h3>
        </Link>
        <div className='bio-small'>{ project.bio }</div>
        <div className='button-container'>

          { project.link &&
          <a href={ project.link } target='_blank' rel='noopener noreferrer'>
            <button className='button button-portfolio button-light'>See Live</button>
          </a>
          }

          {project.page &&
            <Link to={`/portfolio/${ project.page }`}>
              <button
                className='button button-portfolio'>
                More Info
              </button>
            </Link>
          }



        </div>
      </div>

    </div>
  );
};

export default Card;
