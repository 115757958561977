import React, { useState, useEffect } from 'react';
import { Github, Linkedin, Logo } from './Icons';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {

  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const location = useLocation();



  const handleDropdown = (toggle) => {
    setDropdownVisible(toggle);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      const visible = scrollPosition > currentScrollPosition || currentScrollPosition <= 0;
      setScrollPosition(currentScrollPosition);
      setNavbarVisible(visible);
      setDropdownVisible(false);
    };

    if (window) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  return (
    <header id='navbar' className={navbarVisible ? 'slide-in' : 'slide-out'}>
      <div className='nav-collapser'>
        <Link to='/' className='logo-wrapper'>
          <Logo/>
        </Link>
        <div id='nav-toggle'>
          <button
            id='legendMinimizer'
            className={`hamburger hamburger--slider ${(dropdownVisible) ? 'is-active' : ''}`}
            type="button"
            onClick={ () => handleDropdown(!dropdownVisible) }>
            <div className="hamburger-box">
              <div className="hamburger-inner"></div>
            </div>
          </button>
        </div>
      </div>
      <div className={`nav-main-content ${(dropdownVisible) ? 'active' : ''}`}>
        <div className='logo-container'>
            <Link to='/'>
              <Logo/>
            </Link>

        </div>

        <ul className='nav-links'>

          <li>
            <Link to='/' className={location.pathname === '/' ? 'current-page' : ''}>
              home
            </Link>
          </li>

          <li>
            <Link to='/portfolio' className={location.pathname === '/portfolio' ? 'current-page' : ''}>
              portfolio
            </Link>
          </li>

          <li>
            <a
              href='cv.pdf'
              target='_blank'
              rel="noopener noreferrer"
              className={location.pathname === '/cv' ? 'current-page' : ''}>
              cv
            </a>
          </li>

        </ul>

        <div className='nav-image-wrapper'>

            <a
              className='nav-image-container'
              href='https://github.com/matt-grove'
              target='_blank' rel="noopener noreferrer">
              <Github/>
            </a>

            <a
              className='nav-image-container'
              href='https://www.linkedin.com/in/matt-grove-438807a6/'
              target='_blank'
              rel="noopener noreferrer">
              <Linkedin/>
            </a>
        </div>
      </div>

    </header>
  );
};

export default Header;
